import React, { useState } from "react";
import { Icon } from "@fluentui/react/lib/Icon";
import "./MessageTile.scss";

export const MessageComponent = ({
  id,
  username,
  messageText,
  translatedText,
  cardType,
}) => {
  const [isTranslated, switchTranslated] = useState(false);

  const typeClass = "message-tile--" + cardType;

  return (
    <div className={`message-tile ${typeClass}`}>
      <div className={"message-tile__message"}>
        {isTranslated ? translatedText : messageText}
      </div>
      {translatedText && (
        <div
          className={"message-tile__button"}
          onClick={() => switchTranslated(!isTranslated)}
        >
          <Icon
            iconName={
              isTranslated
                ? "MicrosoftTranslatorLogoBlue"
                : "MicrosoftTranslatorLogo"
            }
          />
        </div>
      )}
      <div className={"message-tile__signature"}>{" - " + username}</div>
    </div>
  );
};
