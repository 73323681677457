const bibiImageUri = "./bg1.png";
const towaImageUri = "./bg2.png";

export const getCardType = (index) => {
  if (index % 4 <= 1) {
    return cardTypes.Bibi;
  } else {
    return cardTypes.Towa;
  }
};

export const getCardUrl = (cardType) => {
  switch (cardType) {
    case cardTypes.Bibi:
      return bibiImageUri;
    case cardTypes.Towa:
      return towaImageUri;
    default:
      return;
  }
};

const cardTypes = {
  Bibi: "Bibi",
  Towa: "Towa",
};
