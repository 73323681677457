import React, { useState } from "react";
import "./Credits.scss";
import { Icon } from "@fluentui/react/lib/Icon";

export const Credits = () => {
  const [isOpen, switchPane] = useState(false);
  return (
    <>
      <button className="credits__button" onClick={() => switchPane(!isOpen)}>
        {isOpen ? (
          <Icon iconName={"ChromeClose"} />
        ) : (
          <>
            <Icon iconName={"ContactList"} /> Credits
          </>
        )}
      </button>
      {isOpen && (
        <div className="credits__pane">
          <div className="credits__content">
            Video, Website: Animal Fetish Loli <br />
            Birthday card: Anxient, りりくろ <br />
            Apex Birthday party organizers: Chewyboot, Anxient, XDragonID <br />
            Translations: AshExia, がるるん(Garurune), 直貫誠(Naokima), おばQ,
            らし(Rashi), ツインテ教 <br />
            Organizer: Sacredsun <br /> Contributors: Worldwide Kenzoku.
            <br />
          </div>
        </div>
      )}
    </>
  );
};
