import React, { useState } from "react";
import "./App.scss";
import MessageGridContainer from "./MessageGridContainer";
import { Credits } from "./Credits";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={"./towaowo.png"} className="App-logo" alt="logo" />
        <MessageGridContainer />
        <Credits />
      </header>
    </div>
  );
}

export default App;
