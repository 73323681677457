import React from "react";
import { useMemo } from "react";
import { MessageComponent } from "./MessageComponent.js";
import "./MessageGrid.scss";
import { getCardType } from "./getCardType";

export const MessageGrid = ({ messages }) => {
  const renderedMessages = useMemo(() => {
    return messages.map((m, index) => (
      <MessageComponent
        id={m.id}
        key={m.id}
        username={m.Username}
        messageText={m.msgExJp || m.msgEn}
        translatedText={m.msgJp}
        cardType={getCardType(m.id)}
      />
    ));
  }, [messages]);

  return <div className={"message-grid"}>{renderedMessages}</div>;
};
